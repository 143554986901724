import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { Waypoint } from "react-waypoint";
import LafamaModal from "./LafamaModal";

const Menu = ({ items, categories, setCurrentCategory }) => {
  const [categoriesWithItems, setCategoriesWithItems] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState("");

  useEffect(() => {
    categories.forEach((element) => {
      const itemsToAdd = items.filter((item) => {
        return item.category?.includes(element.key);
      });
      element.items = itemsToAdd;
    });
    setCategoriesWithItems(categories);
  }, [categories, items]);

  const showInfo = (item) => {
    setShowInfoModal(true);
    setInfoModalText(item?.allergens);
  };

  const handleModalClose = () => {
    setShowInfoModal(false);
    setInfoModalText('');
  }

  return (
    <div className="row mt-5">
      <div className="col-12">
        {categoriesWithItems?.map((category, index) => {
          return (
            <div className="row mt-5 mb-5" key={category.id} id={category.key}>
              <div className="col-12">
                <Waypoint
                  topOffset="100px"
                  onLeave={({ previousPosition, currentPosition, event }) => {
                    if (currentPosition === "above") {
                      setCurrentCategory(category.key);
                    }
                  }}
                  onEnter={({ previousPosition, currentPosition, event }) => {
                    if (currentPosition === "inside") {
                      if (index > 0) {
                        setCurrentCategory(categoriesWithItems[index - 1].key);
                      }
                    }
                  }}
                >
                  <h3 className="mb-4">{category.title}</h3>
                </Waypoint>
                {category?.items?.map((item) => {
                  return (
                    <MenuItem
                      item={item}
                      key={item.id}
                      showInfo={() => showInfo(item)}
                    ></MenuItem>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <LafamaModal showModal={showInfoModal} modalText={infoModalText} handleClose={() => handleModalClose()}></LafamaModal>
    </div>
  );
};

export default Menu;
