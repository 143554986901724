import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { menu, categories } from "./data";
import Categories from "./Categories";
import Menu from "./Menu";
import Footer from "./Footer";

function App() {
  const [menuItems, setMenuItems] = useState(menu);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    setCurrentCategory(categories[0].key);
  }, []);

  const filterItems = (category) => {
    if (category === "all") {
      setMenuItems(menu);
      return;
    }
    const newItems = menu.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  return (
    <main className="main">
      <div className="container">
        <div className="d-flex justify-content-center mb-5">
          <img
            src={logo}
            alt="LaFama Logo"
            height={150}
            className="logo mt-5"
          />
        </div>
        <div className="title">
          <h2>Меню</h2>
          <div className="underline"></div>
        </div>
        <Categories
          categories={categories}
          currentCategory={currentCategory}
          filterItems={filterItems}
        />
        <Menu
          items={menuItems}
          categories={categories}
          setCurrentCategory={setCurrentCategory}
        />
        <Footer />
      </div>
    </main>
  );
}

export default App;
