import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const MenuItem = ({ item, showInfo }) => {
  const {
    id,
    title,
    price,
    priceSmall,
    priceBig,
    desc,
    weight,
    image,
    isMenu,
  } = item;
  library.add(faInfoCircle);

  return (
    <div key={id} className="card mb-3 w-100 card-border-bottom">
      <div class="row g-0">
        {image && (
          <div className=" col-2">
            <Zoom classDialog="image-zoom">
              <img src={image} className="img-fluid rounded-start" alt="..." />
            </Zoom>
          </div>
        )}
        <div className="col">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="primary-color">
                {title} {weight && <span className="weight">{weight}</span>}
              </h4>
              <h4 className="primary-color price">
                {price?.toFixed(2)} {price && "лв."}
              </h4>
            </div>
            {isMenu && (
              <div className="d-flex justify-content-between mt-2">
                <h4 className="primary-color">
                  Меню <span className="menu-description">малко</span>
                </h4>
                <h4 className="primary-color price">
                  {priceSmall?.toFixed(2)} {priceSmall && "лв."}
                </h4>
              </div>
            )}
            {isMenu && (
              <div className="d-flex justify-content-between mt-2">
                <h4 className="primary-color">
                  Меню <span className="menu-description">голямо</span>
                </h4>
                <h4 className="primary-color price">
                  {priceBig?.toFixed(2)} {priceBig && "лв."}
                </h4>
              </div>
            )}
            {desc && (
              <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-2 mb-1"></div>
                <p className="item-text pb-3">
                  {desc}{" "}
                  {item?.allergens && (
                    <span role="button">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        onClick={() => showInfo(item)}
                      />
                    </span>
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuItem;
